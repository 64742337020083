import React from 'react';
import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <>
        <section id="contact">
          <header className="major">
            <h2>Drop us a message</h2>
            <p>Do you want more info? Request a free intoduction call or a website analysis here</p>
            {/* We are pretty good at answering emails <span role="img" aria-label="wink">😉</span>*/}
          </header>
        
          <form
            name="contact"
            method="post"
            action="/contact/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <div className="row gtr-uniform gtr-50">
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <div className="col-4 col-12-xsmall">
                <input
                  className="input"
                  type={'text'}
                  name={'name'}
                  onChange={this.handleChange}
                  id={'name'}
                  required={true}
                  placeholder={'Name (required)'}
                />
              </div>
              <div className="col-4 col-12-xsmall">
                <input
                  className="input"
                  type={'email'}
                  name={'email'}
                  onChange={this.handleChange}
                  id={'email'}
                  required={true}
                  placeholder={'Email (required)'}
                />
              </div>
              <div className="col-4 col-12-xsmall">
                <input
                  className="input"
                  type={'text'}
                  name={'phone'}
                  onChange={this.handleChange}
                  id={'phone'}
                  required={false}
                  placeholder={'Phone Number'}
                />
              </div>
              <div className="col-12">
                  <textarea
                    className="textarea"
                    name={'message'}
                    onChange={this.handleChange}
                    id={'message'}
                    required={true}
                    placeholder={'Message (required)'}
                  />
              </div>
              <div className="col-12">
                  <ul className="actions special">
                    <li><input type="submit" value="Get in touch" className="primary" /></li>
                  </ul>
              </div>
            </div>
          </form>
        </section>
      </>
    )
  }
}