import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import Img from "gatsby-image";

export const ContentPageTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
  image,
  children
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div id="main" className="container">
      {helmet || ''}
      <header className="major">
        <h2>{title}</h2>
      </header>

      <section id="content">
        <Img fluid={image} alt={title} className="image fit" />
        <PostContent content={content} />
        {children}
      </section>
    </div>
  );
}

ContentPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
  image: PropTypes.object,
}

const ContentPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ContentPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | AVAYLY">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content=""
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        image={post.frontmatter.image.childImageSharp.fluid}
      />
    </Layout>
  );
}

ContentPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query ContentPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default ContentPage;