import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby';
import Layout from '../components/Layout';
import {ContentPageTemplate} from '../templates/content-page';
import { HTMLContent } from '../components/Content';
import Helmet from 'react-helmet';
import ContactForm from '../components/forms/ContactForm';

const NotFoundPage = ({ data }) => (
  <Layout>
    <ContentPageTemplate
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | AVAYLY">
            <title>Page not found</title>
            <meta
              name="description"
              content="404 error page"
            />
          </Helmet>
        }
        title={'Page not found'}
        image={data.allFile.edges[0].node.childImageSharp.fluid}
      >
        <p>
          You have hit a route that doesn't exist. Try going to <Link to="/">Homepage</Link> or contact us.
        </p>
      </ContentPageTemplate>
      <ContactForm />
  </Layout>
);

export default () => (
    <StaticQuery
        query={graphql`
          query NotFoundQuery {
            allFile (filter:{
                relativePath: {eq: "404.jpg"}
              }){
              edges {
                node {
                  childImageSharp {
                    fluid(quality: 75) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => <NotFoundPage data={data} />}
    />
);
